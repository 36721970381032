<ion-app>
  <ion-menu side="end" menuId="m1" contentId="my-content" swipeGesture="false">
    <!-- <ion-header>
      <ion-toolbar>
      </ion-toolbar>
    </ion-header> -->
    <ion-content>
      <ion-list class="menu-list">
        <ion-menu-toggle menu="m1">
          <ion-item lines="none" routerLink="/profile" (click)="onMenuClick('profile')">
            <ion-icon name="person-circle" slot="start"></ion-icon>
            <ion-label>Profile</ion-label>
          </ion-item>
        </ion-menu-toggle>
        <!-- <ion-menu-toggle menu="m1">
          <ion-item lines="none" routerLink="/courses" (click)="onMenuClick('courses')">
            <ion-icon name="library" slot="start"></ion-icon>
            <ion-label>Courses</ion-label>
          </ion-item>
        </ion-menu-toggle> -->
        <ion-menu-toggle *ngIf="showRush" menu="m1">
          <ion-item lines="none" routerLink="/rush" (click)="onMenuClick('rush')">
            <ion-icon name="alarm" slot="start"></ion-icon>
            <ion-label>Rush</ion-label>
          </ion-item>
        </ion-menu-toggle>
        <!-- <ion-menu-toggle menu="m1">
          <ion-item lines="none" routerLink="/progress" (click)="onMenuClick('progress')">
            <ion-icon name="pulse" slot="start"></ion-icon>
            <ion-label>Progress</ion-label>
          </ion-item>
        </ion-menu-toggle> -->
        <ion-menu-toggle menu="m1">
          <ion-item lines="none" routerLink="/help" (click)="onMenuClick('help')">
            <ion-icon name="help-circle" slot="start"></ion-icon>
            <ion-label>Help</ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle menu="m1">
          <ion-item lines="none" routerLink="/about" (click)="onMenuClick('about')">
            <ion-icon name="information-circle" slot="start"></ion-icon>
            <ion-label>About</ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle menu="m1">
          <ion-item lines="none" (click)="onMenuClick('share')">
            <ion-icon name="share" slot="start"></ion-icon>
            <ion-label>Share</ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle menu="m1">
          <ion-item lines="none" (click)="onMenuClick('logout')">
            <ion-icon color="medium" name="log-out-outline" slot="start"></ion-icon>
            <ion-label color="medium">Log Out</ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
      <ion-list *ngIf="isAdmin">
        <ion-item-divider color="dark">
          <ion-label>
            Admin tools
          </ion-label>
        </ion-item-divider>
        <ion-menu-toggle menu="m1">
          <ion-item lines="none" routerLink="/review" (click)="onMenuClick('review')">
            <ion-icon name="extension-puzzle" slot="start"></ion-icon>
            <ion-label>Question Review</ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle menu="m1">
          <ion-item lines="none" routerLink="/dashboard" (click)="onMenuClick('dashboard')">
            <ion-icon name="bar-chart" slot="start"></ion-icon>
            <ion-label>Dashboard</ion-label>
          </ion-item>
        </ion-menu-toggle>
        <!-- <ion-menu-toggle menu="m1">
          <ion-item lines="none" routerLink="/manage" (click)="onMenuClick('manage')">
            <ion-icon name="people" slot="start"></ion-icon>
            <ion-label>Manage Users</ion-label>
          </ion-item>
        </ion-menu-toggle> -->
        <ion-menu-toggle *ngIf="isQgen" menu="m1">
          <ion-item lines="none" routerLink="/generation" (click)="onMenuClick('generation')">
            <ion-icon name="magnet" slot="start"></ion-icon>
            <ion-label>Puzzle generation</ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
      <ion-list *ngIf="isMagma">
        <ion-item-divider color="dark">
          <ion-label>
            MAGMA tools
          </ion-label>
        </ion-item-divider>
        <ion-menu-toggle menu="m1">
          <ion-item lines="none" routerLink="/download" (click)="onMenuClick('download')">
            <ion-icon name="cloud-download" slot="start"></ion-icon>
            <ion-label>Download data</ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle menu="m1">
          <ion-item lines="none" routerLink="/generation" (click)="onMenuClick('generation')">
            <ion-icon name="magnet" slot="start"></ion-icon>
            <ion-label>Puzzle generation</ion-label>
          </ion-item>
        </ion-menu-toggle>
        <!-- <ion-menu-toggle menu="m1">
          <ion-item lines="none" routerLink="/dashboard" (click)="onMenuClick('dashboard')">
            <ion-icon name="bar-chart" slot="start"></ion-icon>
            <ion-label>Dashboard</ion-label>
          </ion-item>
        </ion-menu-toggle> -->
      </ion-list>
    </ion-content>
  </ion-menu>

  <ion-modal [isOpen]="isModalOpen">
    <ng-template>
      <ion-header>
        <ion-toolbar>
          <ion-title>Share ARI</ion-title>
          <ion-buttons slot="end">
            <ion-button (click)="setOpen(false)">Close</ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-padding">
        <form #f="ngForm" (ngSubmit)="onSubmitEmail(f)">
          <ion-item>
            <ion-label class="input-label" position="floating">Enter your friend's email</ion-label>
            <ion-input class="ion-text-center" type="email" ngModel name="friendEmail" autocomplete="off"
              autocorrect="off" autofocus="true" inputmode="email" required email #inputCtrl="ngModel"></ion-input>
          </ion-item>
          <ion-label *ngIf="!inputCtrl.valid && inputCtrl.touched" class="warning">
            Please enter a valid email
          </ion-label>
          <div class="ion-padding ion-text-center">
            <ion-button type="submit" color="primary" strong="true">
              Submit
            </ion-button>
          </div>
        </form>
        <div *ngIf="emailSent" class="ion-text-center">
          <p>
            Thank you! An email has been sent to your friend.
          </p>
        </div>
      </ion-content>
    </ng-template>
  </ion-modal>

  <ion-router-outlet id="my-content" [swipeGesture]="false"></ion-router-outlet>
</ion-app>