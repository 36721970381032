import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'progress',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
    // canLoad: [AuthGuard]
  },
  {
    path: 'auth',
    // loadChildren: './auth/auth.module#AuthPageModule' 
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthPageModule),
  },
  {
    path: 'progress',
    // loadChildren: './progress/progress.module#ProgressPageModule',
    loadChildren: () => import('./progress/progress.module').then(m => m.ProgressPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'lesson',
    // loadChildren: './lesson/lesson.module#LessonPageModule',
    loadChildren: () => import('./lesson/lesson.module').then(m => m.LessonPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'about',
    // loadChildren: './about/about.module#AboutPageModule'
    loadChildren: () => import('./about/about.module').then(m => m.AboutPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'reset',
    // loadChildren: './auth/reset/reset.module#ResetPageModule'
    loadChildren: () => import('./auth/reset/reset.module').then(m => m.ResetPageModule),
  },
  {
    path: 'password/:token',
    // loadChildren: './auth/password/password.module#PasswordPageModule'
    loadChildren: () => import('./auth/password/password.module').then(m => m.PasswordPageModule),
  },
  {
    path: 'end',
    // loadChildren: './lesson/end/end.module#EndPageModule',
    loadChildren: () => import('./lesson/end/end.module').then(m => m.EndPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'help',
    // loadChildren: './help/help.module#HelpPageModule'
    loadChildren: () => import('./help/help.module').then(m => m.HelpPageModule),
  },
  {
    path: 'rush',
    // loadChildren: './rush/rush.module#RushPageModule',
    loadChildren: () => import('./rush/rush.module').then(m => m.RushPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'courses',
    loadChildren: () => import('./courses/courses.module').then(m => m.CoursesPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'intro',
    loadChildren: () => import('./intro/intro.module').then(m => m.IntroPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfilePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'friend',
    loadChildren: () => import('./friend/friend.module').then(m => m.FriendPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'glass',
    loadChildren: () => import('./glass/glass.module').then(m => m.GlassPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'demo',
    loadChildren: () => import('./demo/demo.module').then(m => m.DemoPageModule)
  },
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'download',
    loadChildren: () => import('./magma/download/download.module').then( m => m.DownloadPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'generation',
    loadChildren: () => import('./magma/generation/generation.module').then( m => m.GenerationPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'manage',
    loadChildren: () => import('./manage/manage.module').then( m => m.ManagePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'landing-sso',
    loadChildren: () => import('./landing-sso/landing-sso.module').then( m => m.LandingSSOPageModule)
  },
  {
    path: 'review',
    loadChildren: () => import('./review/review.module').then( m => m.ReviewPageModule),
    canLoad: [AuthGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
