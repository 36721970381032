import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { KatexModule } from 'ng-katex';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { Camera } from '@ionic-native/camera/ngx';
import { ClickService } from './services/click.service';
import { LearnetPlotService } from './services/learnet-plot.service';
import { LearnetAnimateService } from './services/learnet-animate.service';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { PopoverComponent } from './components/popover/popover.component';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

// import * as PlotlyJS from 'plotly.js-dist-min';
// import { PlotlyModule } from 'angular-plotly.js';
import { PlotlyViaWindowModule } from 'angular-plotly.js';
import { FormsModule } from '@angular/forms';
// import { LocalNotifications } from '@awesome-cordova-plugins/local-notifications/ngx';
// import { LocalNotifications} from '@ionic-native/local-notifications/ngx'

// PlotlyModule.plotlyjs = PlotlyJS;

@NgModule({
  declarations: [AppComponent],
  entryComponents: [PopoverComponent],
  imports: [
    BrowserModule,
    FormsModule,
    KatexModule,
    HttpClientModule,
    IonicModule.forRoot({
      swipeBackEnabled: false, // Doesn't work for browsers...
      mode: 'ios',
      _forceStatusbarPadding: true
    }),
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    AngularFireModule.initializeApp(environment.firebase, 'ari-ml'),
    AngularFirestoreModule,
    AngularFireStorageModule,
    PlotlyViaWindowModule
  ],
  providers: [
    AppVersion,
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Camera,
    ClickService, 
    LearnetPlotService,
    LearnetAnimateService,
    InAppBrowser
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
