import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, from } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Preferences } from '@capacitor/preferences';
import { User } from './user.model';
import { environment } from '../../environments/environment';

// import { environment as devEnvironment } from 'src/environments/environment';
// import { environment as prodEnvironment } from 'src/environments/environment.prod';
// const environment = process.env.NODE_ENV === 'prod' ? prodEnvironment : devEnvironment;

export interface AuthResponseData {
  id: number;
  username: string;
  email: string;
  courses: string;
  affiliation: string;
  image: string;
  friends: string;
  role: string;
  group: string;
  token: string;
  expiresIn: number;
  privateUser: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  private _user = new BehaviorSubject<User>(null);
  private activeLogoutTimer: any;

  get userIsAuthenticated() {
    return this._user.asObservable().pipe(
      map(user => {
        if (user) {
          return !!user['token'];
        } else {
          return false;
        }
      })
    );
  }

  get userId() {
    return this._user.asObservable().pipe(
      map(user => {
        if (user) {
          return user['id'];
        } else {
          return null;
        }
      })
    );
  }

  get token() {
    return this._user.asObservable().pipe(
      map(user => {
        if (user) {
          return user['token'];
        } else {
          return null;
        }
      })
    );
  }

  constructor(private http: HttpClient) { }

  autoLogin() {
    return from(Preferences.get({ key: 'authData' })).pipe(
      map(storedData => {
        if (!storedData || !storedData['value']) {
          console.log('No stored data found!');
          return null;
        }
        const parsedData = JSON.parse(storedData['value']) as {
          userId: number;
          username: string;
          email: string;
          courses: string;
          affiliation: string;
          image: string;
          friends: string;
          role: string;
          group: string;
          token: string;
          tokenExpirationDate: string;
          privateUser: boolean;
        };
        const expirationTime = new Date(parsedData.tokenExpirationDate);
        if (expirationTime <= new Date()) {
          return null;
        }
        const user = new User(
          parsedData.userId,
          parsedData.username,
          parsedData.email,
          parsedData.courses,
          parsedData.affiliation,
          parsedData.image,
          parsedData.friends,
          parsedData.role,
          parsedData.group,
          parsedData.token,
          expirationTime,
          parsedData.privateUser
        );
        return user;
      }),
      tap(user => {
        if (user) {
          this._user.next(user);
          // this.autoLogout(user.tokenDuration);
          this.autoLogout(user['tokenDuration']);
        }
      }),
      map(user => {
        return !!user;
      })
    );
  }

  signup(username: string, email: string, password: string, affiliation: string) {
    return this.http
      .post<AuthResponseData>(`${environment.APIUrl}/newuser`, {
        username,
        email,
        password,
        affiliation
      })
      .pipe(tap(this.setUserData.bind(this)));
  }

  login(email: string, password: string) {
    return this.http
      .post<AuthResponseData>(`${environment.APIUrl}/login`, {
        email,
        password
      })
      .pipe(tap(this.setUserData.bind(this)));
  }

  logout() {
    if (this.activeLogoutTimer) {
      clearTimeout(this.activeLogoutTimer);
    }
    this._user.next(null);
    Preferences.remove({ key: 'authData' });
  }

  ngOnDestroy() {
    if (this.activeLogoutTimer) {
      clearTimeout(this.activeLogoutTimer);
    }
  }

  private autoLogout(duration: number) {
    if (this.activeLogoutTimer) {
      clearTimeout(this.activeLogoutTimer);
    }
    this.activeLogoutTimer = setTimeout(() => {
      this.logout();
    }, duration);
  }

  public setUserData(userData: AuthResponseData) {
    const expirationTime = new Date(
      new Date().getTime() + userData.expiresIn * 1000
    );
    const user = new User(
      userData.id,
      userData.username,
      userData.email,
      userData.courses,
      userData.affiliation,
      userData.image,
      userData.friends,
      userData.role,
      userData.group,
      userData.token,
      expirationTime,
      userData.privateUser
    );
    this._user.next(user);
    this.autoLogout(user.tokenDuration);
    this.storeAuthData(
      userData.id,
      userData.username,
      userData.email,
      userData.courses,
      userData.affiliation,
      userData.image,
      userData.friends,
      userData.role,
      userData.group,
      userData.token,
      expirationTime.toISOString(),
      userData.privateUser
    );
  }

  storeAuthData(
    userId: number,
    username: string,
    email: string,
    courses: string,
    affiliation: string,
    image: string,
    friends: string,
    role: string,
    group: string,
    token: string,
    tokenExpirationDate: string,
    privateUser: boolean
  ) {
    const data = JSON.stringify({
      userId,
      username,
      email,
      courses,
      affiliation,
      image,
      friends,
      role,
      group,
      token,
      tokenExpirationDate,
      privateUser
    });
    Preferences.set({ key: 'authData', value: data });
  }
}

