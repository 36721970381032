import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';

// import { environment as devEnvironment } from 'src/environments/environment';
// import { environment as prodEnvironment } from 'src/environments/environment.prod';
// const environment = process.env.NODE_ENV === 'production' ? prodEnvironment : devEnvironment;

declare var Plotly: any;

interface LearnetResponseData {
  xs: number[];
  ys: number[];
  zs: number[];
  colors: string[];
  sizes: number[];
  topics: string[];
  eye: number[];
  simil20: any[];
  mean_grasp: number;
}

@Injectable({
  providedIn: 'root'
})
export class LearnetPlotService {

  public data: any;
  public xs: any;
  public ys: any;
  public zs: any;
  public sizes: any;
  public colors: any;
  public topics: any;
  public eye: any;
  public simils: any;
  public meanGrasp: number;
  public layout: any;
  public config = { displayModeBar: false, responsive: true };

  public hoverOn = false;
  public hoverActive = true;
  public topicHoveredPrev = '';

  constructor(
    public http: HttpClient,
    public authService: AuthService,
  ) { }


  getLearnet(simil: boolean, generate: boolean) {

    console.log("EEEEEEE", environment.APIUrl)
    if (!generate) {
      const URL = `${environment.APIUrl}/getlearnet`;
      return this.authService.token.pipe(
        take(1),
        switchMap(token => {
          return this.http.post<LearnetResponseData>(URL, {
            token,
            simil
          });
        }),
        tap(resData => {
          console.log('LEARNET', resData);
        })
      );
    }
    if (generate) {
      console.log('GENERATE LEARNET....')
    }
  }

  plotLearnet(myPlot, myGraph, eyeInitial, simil, generate = false) {
    // return this.getLearnet().subscribe(resData => {
    if (this.xs && !(this.xs[0] == 1.4916)) { // TEMP: reload learnet if first x comes from demo
      this.plotGraph(myPlot, myGraph, simil);
      const promise1 = new Promise((resolve) => {
        resolve('Success!');
      });
      return promise1;
    }
    return this.getLearnet(simil, generate).pipe(tap(resData => {
      // console.log('RESDATA', resData)
      this.xs = resData['xs'];
      this.ys = resData['ys'];
      this.zs = resData['zs'];
      this.sizes = resData['sizes'];
      this.colors = resData['colors'];
      // ////// ROUND sizes and alphas to avoid iOS bugs (done in the backend)
      // this.sizes = this.sizes.map(x => Math.round(x));
      // this.colors = this.colors.map(x => this.roundAlphaForRGBA(x));
      // console.log('ROUNDED SIZE AND COLORS', this.sizes, this.colors)
      // /////////
      this.topics = resData['topics'];
      // this.eye = resData.eye;
      this.eye = eyeInitial;
      this.simils = resData['simil20'];
      this.meanGrasp = resData['mean_grasp'];
      this.data = [{
        x: this.xs,
        y: this.ys,
        z: this.zs,
        type: 'scatter3d',
        mode: 'markers',
        opacity: 1,
        marker: {
          size: this.sizes,
          color: this.colors,
          opacity: 1,  // Required to avoid bugs with iOS!
          line: { width: 0 }
        },
        text: this.topics,
        hoverinfo: simil ? 'text' : 'none',
        // hoverlabel: { bgcolor: this.colors },
      }]
      this.layout = {
        margin: { t: 0, b: 0, l: 0, r: 0 },
        scene: {
          bgcolor: 'rgb(10, 10, 40)',
          aspectratio: { x: 1, y: 1, z: 1 },
          camera: {
            center: { x: 0, y: 0, z: 0 },
            eye: { x: this.eye[0], y: this.eye[1], z: this.eye[2] },
            up: { x: 0, y: 0, z: 1 }
          },
          xaxis: { visible: false },
          yaxis: { visible: false },
          zaxis: { visible: false },
          dragmode: true   // rotations and zoom
        },
        hovermode: 'closest',
        dragmode: 'orbit',
        plot_bgcolor: 'rgba(0, 0, 0, 0)',
        paper_bgcolor: 'rgba(0, 0, 0, 0)',
        // annotations: [
        //   {
        //     x: 2,
        //     y: 0.5,
        //     xref: 'x',
        //     yref: 'y',
        //     text: 'Annotation Text',
        //     showarrow: true,
        //     arrowhead: 7,
        //     ax: 0,
        //     ay: -40
        //   }
        // ]
      };
      return this.plotGraph(myPlot, myGraph, simil);
    }),
      first()
    ).toPromise();
  }

  plotGraph(myPlot, myGraph, simil) {
    Plotly.newPlot(
      myPlot,
      this.data,
      this.layout,
      this.config
    );

    function touchHandler(event) {
      // console.log(`touchHandler triggered for event ${event.type}`);
      const touches = event.changedTouches;
      const first = touches[0];
      let type = '';
      switch (event.type) {
        case 'touchstart': type = 'mousedown'; break;
        case 'touchend': type = 'mouseup'; break;
        default: return;
      }
      const opts = {
        bubbles: true,
        screenX: first.screenX,
        screenY: first.screenY,
        clientX: first.clientX,
        clientY: first.clientY,
      };
      const simulatedEvent = new MouseEvent(type, opts);
      first.target.dispatchEvent(simulatedEvent);
      event.preventDefault();
    }
    if (simil) {
      // Touch events for mobile
      myGraph.nativeElement.addEventListener('touchstart', (event) => touchHandler(event));
      myGraph.nativeElement.addEventListener('touchend', (event) => touchHandler(event));
      // Hover events
      myPlot.on('plotly_hover', (data) => {
        const topicHovered = data.points[0].text;
        if (this.hoverActive || topicHovered !== this.topicHoveredPrev) {
          this.hoverActive = false;
          this.topicHoveredPrev = topicHovered;
          const ptNum = Number(data.points[0].pointNumber);
          const simils = Object.create(this.simils[ptNum]);
          simils.unshift([ptNum, 1.0]);
          const k = 8;
          const changedPoints = [];
          for (let i = 0; i < k && i < simils.length; i++) {
            const num = simils[i][0];
            const sim = simils[i][1];
            const size0 = this.sizes[num];
            const color0 = this.colors[num];
            changedPoints.unshift([num, size0, color0]);
            this.sizes[num] = Math.max(0, (1 + sim ** 1) * size0);
            this.colors[num] = this.opacify(color0);
          }
          const update = {
            marker: {
              size: this.sizes,
              color: this.colors,
              opacity: 1,
              line: { width: 0 }
            },
          };
          Plotly.restyle(myPlot, update, 0);
          for (let i = 0; i < k && i < simils.length; i++) {
            const num = changedPoints[i][0];
            this.sizes[num] = changedPoints[i][1];
            this.colors[num] = changedPoints[i][2];
          }
        }
      })
        .on('plotly_unhover', () => {
          if (!this.hoverActive) {
            this.hoverActive = true;
            const update = {
              marker: {
                size: this.sizes,
                color: this.colors,
                opacity: 1,
                line: { width: 0 }
              },
            };
            Plotly.restyle(myPlot, update, 0);
          }
        });
    }
    // return Plotly.newPlot(
    //   myPlot,
    //   this.data,
    //   this.layout,
    //   this.config
    // );
  }

  opacify(rgbaColor) {
    const rgba = rgbaColor.match(/\((.*?)\)/)[1].split(', ');
    const red = Math.min(255, Math.floor(rgba[0] / rgba[3]));
    const green = Math.min(255, Math.floor(rgba[1] / rgba[3]));
    const blue = Math.min(255, Math.floor(rgba[2] / rgba[3]));
    const rgbColor = `rgb(${red}, ${green}, ${blue})`;
    return rgbColor;
  }
  roundAlphaForRGBA(rgbaColor) {
    const rgba = rgbaColor.match(/\((.*?)\)/)[1].split(', ');
    const red = rgba[0];
    const green = rgba[1];
    const blue = rgba[2];
    const alpha = 1.0; //Math.floor(100*rgba[3])/100;
    const rgbaColorRound = `rgba(${red}, ${green}, ${blue}, ${alpha})`;
    return rgbaColorRound;
  }

  //////////////
  // Demo page 

  // getDemoLearnet() {
  //   const URL = `${environment.APIUrl}/getdemolearnet`;
  //   return this.authService.token.pipe(
  //     take(1),
  //     switchMap(token => {
  //       return this.http.post<LearnetResponseData>(URL, {});
  //     }),
  //     tap(resData => {
  //       console.log('LEARNET', resData);
  //     })
  //   );
  // }

  getDemoLearnet() {
    const URL = `${environment.APIUrl}/getdemolearnet`;
    return this.http.post<LearnetResponseData>(URL, {});
  }

  plotDemoLearnet(myPlot, myGraph, eyeInitial, simil, generate = false) {
    return this.getDemoLearnet().pipe(tap(resData => {
      // console.log('RESDATA', resData)
      this.xs = resData['xs'];
      this.ys = resData['ys'];
      this.zs = resData['zs'];
      this.sizes = resData['sizes'];
      this.colors = resData['colors'];
      // ////// ROUND sizes and alphas to avoid iOS bugs (done in the backend)
      // this.sizes = this.sizes.map(x => Math.round(x));
      // this.colors = this.colors.map(x => this.roundAlphaForRGBA(x));
      // console.log('ROUNDED SIZE AND COLORS', this.sizes, this.colors)
      // /////////
      this.topics = resData['topics'];
      // this.eye = resData.eye;
      this.eye = eyeInitial;
      this.simils = resData['simil20'];
      this.meanGrasp = resData['mean_grasp'];
      this.data = [{
        x: this.xs,
        y: this.ys,
        z: this.zs,
        type: 'scatter3d',
        mode: 'markers',
        opacity: 1,
        marker: {
          size: this.sizes,
          color: this.colors,
          opacity: 1,  // Required to avoid bugs with iOS!
          line: { width: 0 }
        },
        text: this.topics,
        hoverinfo: simil ? 'text' : 'none',
        // hoverlabel: { bgcolor: this.colors },
      }]
      this.layout = {
        margin: { t: 0, b: 0, l: 0, r: 0 },
        scene: {
          bgcolor: 'rgb(10, 10, 40)',
          aspectratio: { x: 1, y: 1, z: 1 },
          camera: {
            center: { x: 0, y: 0, z: 0 },
            eye: { x: this.eye[0], y: this.eye[1], z: this.eye[2] },
            up: { x: 0, y: 0, z: 1 }
          },
          xaxis: { visible: false },
          yaxis: { visible: false },
          zaxis: { visible: false },
          dragmode: true   // rotations and zoom
        },
        hovermode: 'closest',
        dragmode: 'orbit',
        plot_bgcolor: 'rgba(0, 0, 0, 0)',
        paper_bgcolor: 'rgba(0, 0, 0, 0)',
        // annotations: [
        //   {
        //     x: 2,
        //     y: 0.5,
        //     xref: 'x',
        //     yref: 'y',
        //     text: 'Annotation Text',
        //     showarrow: true,
        //     arrowhead: 7,
        //     ax: 0,
        //     ay: -40
        //   }
        // ]
      };
      return this.plotGraph(myPlot, myGraph, simil);
    }),
      first()
    ).toPromise();
  }


}
