import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { App } from '@capacitor/app';
import { Preferences } from '@capacitor/preferences';
import { AuthService } from './auth/auth.service';
import { Subscription } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ClickService } from './services/click.service';
import { LearnetPlotService } from './services/learnet-plot.service';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private authSub: Subscription;
  private previousAuthState = false;
  public affiliation: string;
  public role: string;
  public isEHL = false;
  public isEPFL = false;
  public isAdmin = false;
  public isMagma = false;
  isQgen = false;
  public isModalOpen = false;
  public emailSent = false;
  public showRush = true;

  constructor(
    private platform: Platform,
    private authService: AuthService,
    private router: Router,
    public statusBar: StatusBar,
    public clickService: ClickService,
    private learnetPlot: LearnetPlotService,
    private http: HttpClient
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleLightContent();
      this.statusBar.overlaysWebView(false);
      if (Capacitor.isPluginAvailable('SplashScreen')) {
        SplashScreen.hide();
      }
    });
  }

  ngOnInit() {
    this.authSub = this.authService.userIsAuthenticated.subscribe(isAuth => {
      if (!isAuth && this.previousAuthState !== isAuth) {
        this.router.navigateByUrl('home');
      }
      this.previousAuthState = isAuth;
    });
    App.addListener('appStateChange', ({ isActive }) => {
      this.checkAuthOnResume(isActive);
    });
    this.getStoredItems();
  }

  async getStoredItems() {
    const data = await Preferences.get({ key: 'authData' });
    if (data.value) {
      this.affiliation = JSON.parse(data.value).affiliation;
      this.role = JSON.parse(data.value).role;
      if (this.role != null) {
        this.isAdmin = (this.role.toLowerCase().includes('admin')) ? true : false;
        this.isMagma = (this.role.toLowerCase().includes('magma')) ? true : false;
        this.isQgen = (this.role.toLowerCase().includes('qgen')) ? true : false;
      }
      if (this.affiliation === 'skema') {
        this.showRush = false;
      }
    }
  }

  /*  This triggers each time tabs are switched in browser
      because of checkAuthOnResume below */
  onLogout() {
    this.authService.logout();
    // this.router.navigateByUrl('auth');
    // this.router.navigateByUrl('home');
  }

  onMenuClick(button: string) {
    this.clickService.addClick('menu', '', button).subscribe();
    if (button === 'logout') {
      this.authService.logout();
    }
    if (button === 'share') {
      this.isModalOpen = true;
    }
  }

  ngOnDestroy() {
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
  }

  private checkAuthOnResume(isActive: boolean) {
    if (isActive) {
      this.authService
        .autoLogin()
        .pipe(take(1))
        .subscribe(success => {
          if (!success) {
            this.onLogout();
          }
        });
    }
  }

  setOpen(isOpen: boolean) {
    this.isModalOpen = isOpen;
  }

  onSubmitEmail(form: NgForm) {
    this.emailSent = false;
    if (!form.valid) {
      console.log('Invalid form');
      return;
    }
    const friendEmail = form.value.friendEmail;
    form.reset();
    this.authService.token.pipe(
      take(1),
      switchMap(token => {
        const URL = `${environment.APIUrl}/invitefriend`;
        return this.http.post(URL, {
          token,
          email: friendEmail
        });
      }),
      tap(resData => {
        console.log('RESDATA INVITE:', resData);
        this.emailSent = true;
      })
    ).subscribe();
  }

}
