import { Injectable } from '@angular/core';
import { LearnetPlotService } from './learnet-plot.service';

declare var Plotly: any;

@Injectable({
  providedIn: 'root'
})
export class LearnetAnimateService {

  constructor(private learnetPlot: LearnetPlotService) { }

  animateEye(plot, eyeNext, duration) {
    // console.log('ANIMATE EYE')
    const eyePrev = this.learnetPlot.eye;
    this.learnetPlot.eye = eyeNext;
    const frameDuration = 40; // ms  (so 40ms = 25 frames per second)
    let n = Number(1000 * duration / frameDuration);
    // const framesAnim = [];
    const layout_updates = [];
    for (let i = 0; i <= n; i++) {
      // const eyeX = Math.round(100 * (1 - i / n) * eyePrev[0] + (i / n) * eyeNext[0]) / 100;
      // const eyeY = Math.round(100 * (1 - i / n) * eyePrev[1] + (i / n) * eyeNext[1]) / 100;
      // const eyeZ = Math.round(100 * (1 - i / n) * eyePrev[2] + (i / n) * eyeNext[2]) / 100;
      const eyeX = (1 - i / n) * eyePrev[0] + (i / n) * eyeNext[0];
      const eyeY = (1 - i / n) * eyePrev[1] + (i / n) * eyeNext[1];
      const eyeZ = (1 - i / n) * eyePrev[2] + (i / n) * eyeNext[2];
      // framesAnim[i] = { layout: { scene: { camera: { eye: { x: eyeX, y: eyeY, z: eyeZ } } } } };
      layout_updates[i] = { 'scene.camera.eye': { x: eyeX, y: eyeY, z: eyeZ } };
    }
    // this.learnetPlot.eye = eyeNext;
    // return Plotly.animate(plot, framesAnim, {
    //   frame: { duration: frameDuration }
    // });
    for (let i = 0; i <= n; i++) {
      setTimeout(() => {
        Plotly.relayout(plot, layout_updates[i]);
      }, frameDuration * i);
    }
  }

  animateEyeGrasp(plot, eyeNext, topicId, duration) {
    // console.log('ANIMATE EYE GRASP');
    let data_updates = [];
    let layout_updates = [];
    let colors_updates = [];
    const frameDuration = 40; // ms  (so 40ms = 25 frames per second)
    const n = Number(1000 * duration / frameDuration);
    const eyePrev = this.learnetPlot.eye;
    if (topicId >= 0) {
      let sizes = this.learnetPlot.sizes;
      let colors = this.learnetPlot.colors;
      // Initial size and color
      const size0 = sizes[topicId];
      const color0 = colors[topicId];
      const rgba0 = color0.substring(5, color0.length - 1).split(',').map(Number);
      const r0 = rgba0[0];
      const g0 = rgba0[1];
      const b0 = rgba0[2];
      const a0 = rgba0[3];
      // Final size and color
      const size1 = size0 * (1 + (0.2 / a0));   // size0 + (10 * a0) ???...
      const color1 = this.learnetPlot.opacify(color0);
      const rgb1 = color1.substring(4, color1.length - 1).split(',').map(Number);
      const r1 = rgb1[0];
      const g1 = rgb1[1];
      const b1 = rgb1[2];
      const a1 = 1;
      for (let i = 0; i <= n; i++) {
        // Eye update
        const eyeX = (1 - i / n) * eyePrev[0] + (i / n) * eyeNext[0];
        const eyeY = (1 - i / n) * eyePrev[1] + (i / n) * eyeNext[1];
        const eyeZ = (1 - i / n) * eyePrev[2] + (i / n) * eyeNext[2];
        // Grasp update
        const beatTime = 0.6 * n;
        const beatSize = 5;
        let sizei = i < beatTime ? (1 - i / n) * size0 + (i / n) * size1 * beatSize : (1 - i / n) * size1 * beatSize + (i / n) * size1;
        sizei = Math.round(sizei);
        const ri = Math.round((1 - i / n) * r0 + (i / n) * r1);
        const gi = Math.round((1 - i / n) * g0 + (i / n) * g1);
        const bi = Math.round((1 - i / n) * b0 + (i / n) * b1);
        const ai = Math.round(100 * ((1 - i / n) * a0 + (i / n) * a1)) / 100;
        const rgbai = `rgba(${ri}, ${gi}, ${bi}, ${ai})`;
        // const rgbai = 'rgb(217, 46, 63)'; // `rgb(${ri}, ${gi}, ${bi})`;
        sizes[topicId] = sizei;
        colors[topicId] = rgbai;
        const sizesi = Object.assign([], sizes);
        let colorsi = Object.assign([], colors);
        // const colorsi = Array.apply(null, Array(74)).map(String.prototype.valueOf,"rgb(217, 46, 163)")
        // colorsi = colorsi.map(this.learnetPlot.opacify);
        // console.log('COLORS i', colorsi);
        // Data and layout updates
          data_updates[i] = {
            // opacity: 1,
            // marker: {
            //   size: sizesi,
            //   color: colorsi,  // bugs on iOS... See maybe https://github.com/plotly/plotly.js/issues/144
            //   opacity: 1,
            //   line: { width: 0 }
            // },
            'marker.size': [sizesi],
            'marker.color': [colorsi],
            // 'marker.size[2]': [sizei],
            // 'marker.color[2]': [rgbai],
          };
        layout_updates[i] = { 'scene.camera.eye': { x: eyeX, y: eyeY, z: eyeZ } };
        colors_updates[i] = colorsi;
      }
      this.learnetPlot.eye = eyeNext;
      this.learnetPlot.sizes = sizes;
      this.learnetPlot.colors = colors;
    } else {
      console.log('no TOPICID!', topicId);
      for (let i = 0; i <= n; i++) {
        // Eye update
        const eyeX = (1 - i / n) * eyePrev[0] + (i / n) * eyeNext[0];
        const eyeY = (1 - i / n) * eyePrev[1] + (i / n) * eyeNext[1];
        const eyeZ = (1 - i / n) * eyePrev[2] + (i / n) * eyeNext[2];
        // Layout update
        data_updates[i] = {};
        layout_updates[i] = { 'scene.camera.eye': { x: eyeX, y: eyeY, z: eyeZ } };
      }
      this.learnetPlot.eye = eyeNext;
    }
    for (let i = 0; i <= n; i++) {
      setTimeout(() => {
        // Plotly.restyle(plot, data_updates[i]);
        // Plotly.restyle(plot, {'marker.size[2]': 64, 'marker.color[2]': 'red'}, null, [0])
        // Plotly.restyle(plot, {'marker.color[23]': 'red'}, null, [0])
        Plotly.update(plot, data_updates[i], layout_updates[i]);
        // Plotly.restyle(plot, 'marker.color', [colors_updates[i]]);
        // Plotly.update(plot, data_updates[i], {});
      }, frameDuration * i);
    }
  }

  animateDemoDark(plot, eyeNext, duration) {
    let data_updates = [];
    let layout_updates = [];
    const frameDuration = 40; // ms  (so 40ms = 25 frames per second)
    const n = Number(1000 * duration / frameDuration);
    const eyePrev = this.learnetPlot.eye;
    let sizes = this.learnetPlot.sizes;
    let colors0 = this.learnetPlot.colors;
    let colors = Object.assign([], colors0);
    for (let i = 0; i <= n; i++) {
      // Eye update
      const eyeX = (1 - i / n) * eyePrev[0] + (i / n) * eyeNext[0];
      const eyeY = (1 - i / n) * eyePrev[1] + (i / n) * eyeNext[1];
      const eyeZ = (1 - i / n) * eyePrev[2] + (i / n) * eyeNext[2];
      // Grasp update
      for (let topicId = 0; topicId <= 99; topicId++) {
        const color0 = colors0[topicId];
        const rgba0 = color0.substring(5, color0.length - 1).split(',').map(Number);
        const a0 = rgba0[3];
        const r0 = Math.min(255, Math.floor(rgba0[0] / a0));
        const g0 = Math.min(255, Math.floor(rgba0[1] / a0));
        const b0 = Math.min(255, Math.floor(rgba0[2] / a0));
        // ith color
        const a1 = 0.2
        let ai = (1 - i / n) * a0 + (i / n) * a1;
        ai = Math.round(ai * 100) / 100;
        const ri = Math.min(255, Math.floor(r0 * ai));
        const gi = Math.min(255, Math.floor(g0 * ai));;
        const bi = Math.min(255, Math.floor(b0 * ai));;
        const rgbai = `rgba(${ri}, ${gi}, ${bi}, ${ai})`;
        colors[topicId] = rgbai;
      }
      const colorsi = Object.assign([], colors);
      // Data and layout updates
      data_updates[i] = { marker: { size: sizes, color: colorsi, opacity: 1, line: { width: 0 } } };
      layout_updates[i] = { 'scene.camera.eye': { x: eyeX, y: eyeY, z: eyeZ } };
    }
    this.learnetPlot.eye = eyeNext;
    // this.learnetPlot.sizes = sizes;
    this.learnetPlot.colors = colors;

    for (let i = 0; i <= n; i++) {
      setTimeout(() => {
        Plotly.update(plot, data_updates[i], layout_updates[i]);
      }, frameDuration * i);
    }
  }

  animateDemoEyeGrasp(plot, eyeNext, topicId, similDict, duration) {
    const data_updates = [];
    const layout_updates = [];
    const frameDuration = 40; // ms  (so 40ms = 25 frames per second)
    const n = Number(1000 * duration / frameDuration);
    const eyePrev = this.learnetPlot.eye;
    // const sizes = this.learnetPlot.sizes;
    const sizes0 = this.learnetPlot.sizes;
    const sizes = Object.assign([], sizes0);
    const colors0 = this.learnetPlot.colors;
    const colors = Object.assign([], colors0);
    for (let i = 0; i <= n; i++) {
      // Eye update
      const eyeX = (1 - i / n) * eyePrev[0] + (i / n) * eyeNext[0];
      const eyeY = (1 - i / n) * eyePrev[1] + (i / n) * eyeNext[1];
      const eyeZ = (1 - i / n) * eyePrev[2] + (i / n) * eyeNext[2];
      // Grasp update
      similDict[topicId] = 1;
      for (const key of Object.keys(similDict)) {
        let simil = similDict[key];
        simil = Math.pow(simil, 1)
        // Initial size and color
        const size0 = sizes0[key];
        const color0 = colors0[key];
        const rgba0 = color0.substring(5, color0.length - 1).split(',').map(Number);
        const r0 = rgba0[0];
        const g0 = rgba0[1];
        const b0 = rgba0[2];
        const a0 = rgba0[3];
        // Final size and color
        const color1 = this.learnetPlot.opacify(color0);
        const rgb1 = color1.substring(4, color1.length - 1).split(',').map(Number);
        let a1 = Math.min(1, Math.max(0.1, a0 + simil));
        a1 = Math.round(a1 * 100) / 100;
        const r1 = Math.min(255, Math.floor(rgb1[0] * a1));
        const g1 = Math.min(255, Math.floor(rgb1[1] * a1));
        const b1 = Math.min(255, Math.floor(rgb1[2] * a1));
        const size1 = size0 * (1 + 0.2 * simil);   // size0 + (10 * a0) ???...
        const bt = 0.6 * n; // beatTime
        const beatSize = Math.max(1.1, 1.5 * simil);
        let sizei = 1;
        if (i < bt) {
          sizei = (1 - i / bt) * size0 + (i / bt) * size1 * beatSize
        } else {
          sizei = (1 - (i - bt) / (n - bt)) * size1 * beatSize + ((i - bt) / (n - bt)) * size1;
        }
        const ai = (1 - i / n) * a0 + (i / n) * a1;
        const ri = (1 - i / n) * r0 + (i / n) * r1;;
        const gi = (1 - i / n) * g0 + (i / n) * g1;;
        const bi = (1 - i / n) * b0 + (i / n) * b1;;
        const rgbai = `rgba(${ri}, ${gi}, ${bi}, ${ai})`;
        colors[key] = rgbai;
        sizes[key] = sizei;
      }
      const colorsi = Object.assign([], colors);
      const sizesi = Object.assign([], sizes);
      // Data and layout updates
      data_updates[i] = { marker: { size: sizesi, color: colorsi, opacity: 1, line: { width: 0 } } };
      layout_updates[i] = { 'scene.camera.eye': { x: eyeX, y: eyeY, z: eyeZ } };
    }
    this.learnetPlot.eye = eyeNext;
    this.learnetPlot.sizes = sizes;
    this.learnetPlot.colors = colors;

    for (let i = 0; i <= n; i++) {
      setTimeout(() => {
        Plotly.update(plot, data_updates[i], layout_updates[i]);
      }, frameDuration * i);
    }
  }



}
