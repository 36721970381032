export class User {
  constructor(
    public id: number,
    public username: string,
    public email: string,
    public courses: string,
    public affiliation: string,
    public image: string,
    public friends: string,
    public role: string,
    public group: string,
    private _token: string,
    private tokenExpirationDate: Date,
    private privateUser: boolean
  ) {}

  get token() {
      if (!this.tokenExpirationDate || this.tokenExpirationDate <= new Date()) {
          console.log('Token expired!');
          return null;
      }
      return this._token;
  }

  get tokenDuration() {
    if (!this.token) {
      return 0;
    }
    return this.tokenExpirationDate.getTime() - new Date().getTime();
  }
}
