import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { take, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ClickService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  addClick(page: string, topic: string, button: string) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        const URL = `${environment.APIUrl}/addclick`;
        return this.http.post(URL, { token, page, topic, button });
      })
    );
  }

}
