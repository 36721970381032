export const environment = {
  production: true,
  // APIUrl: 'http://127.0.0.1:5000/api',
  APIUrl: 'https://www.magmalearning.com/api',
  AriAPIUrl: 'https://ari9000api.herokuapp.com/api',
  firebase: {
    apiKey: "AIzaSyCN5IHCqS1qiy4HJWx_A3rfGU3v-Cho1-g",
    authDomain: "ari-ml.firebaseapp.com",
    databaseURL: "https://ari-ml.firebaseio.com",
    projectId: "ari-ml",
    storageBucket: "ari-ml.appspot.com",
    messagingSenderId: "1084307662193",
    appId: "1:1084307662193:web:c8833f54dce2a0934b42d0",
    measurementId: "G-VJG0RN3L0T"
  }
};
